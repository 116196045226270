import React from "react"
import _ from "lodash"
import TechPage from '../components/TechPage'
import Icon from '../components/Icon'
import IllustrationInterp from '../media/illustration/New.png'
import BlogCard from "../components/BlogCard"


export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "interoperability",
      title: "Interopérabilité des données de santé",
      description: "Les standards comme FHIR ou OMOP peuvent aider à briser les silos, permettant une meilleure communication entre différents systèmes d'information en santé.",
      articles:
      <section class="alt-section">
          <div class="container">
          <h1 class="title">En apprendre davantage :</h1>
            <div class="columns">
              <div class="column is-7">
                    <BlogCard 
                     title= "Vocabularies : Interoperability's most powerful tool"
                     url= "/blog/vocabulary-and-interoperability"
                     minutes= "2"
                     date= "June 21"
                     author= "Jorge Arellona"
                     description= "The WHAT, HOW and WHY of terminology standards as essential instruments in today's digital health and interoperability markets."
                    />
                    <BlogCard 
                     title= "Qu'est-ce que l'intéropérabilité ? Réponse appliquée au domaine de la santé."
                     url= "/blog/definition-interoperabilite"
                     minutes= "2"
                     date= "May 31"
                     author= "Anthony Dubois"
                     description= "Le mot intéropérabilité est à la mode dans le monde de la santé et à juste titre, revenons sur ces définitions et ses cas d'usages."
                    />
                    <BlogCard 
                     title= "Déployer l’INS, les grandes étapes"
                     url= "/blog/mettre-en-place-ins"
                     minutes= "2"
                     date= "April 30"
                     author= "Anthony Dubois"
                     description= "Un guide pratique pour comprendre les grandes étapes nécessaires à la mise en place de l'INS dans votre logiciel de santé."
                    />
              </div>
            </div>
          </div>
        </section>,
      content:
      <div>
        <div class="columns section-panel">
            <div class="column alt-column is-8">
                <div class="content is-medium">
                    <p>En France, nos patients atteints de maladies chroniques continuent de se déplacer avec leur <strong>dossier médical papier</strong>. L’une des raisons est que chaque hôpital possède ses propres systèmes d’information qui ne savent pas nécessairement <strong>communiquer entre eux</strong>, même parfois au sein du même hôpital.</p>
                    <p>Malgré les avancées de ces dernières années et la volonté des gouvernements à améliorer le système (dossier médical partagé), de nombreux défis restent à relever.</p>
                    <p>Akimed aide ses partenaires sur les sujets suivants&nbsp;:</p>
                    <ul>
                        <li>la transformation de leur donnée de santé vers des <strong>standards éprouvés, comme FHIR ou OMOP</strong>&nbsp;;</li>
                        <li>le développement d’<strong>applications métiers</strong> qui s’appuient sur ces standards (application mobiles ou web, basées sur le cloud comme les <strong>SMART on FHIR</strong>)&nbsp;;</li>
                        <li>la création d’<strong>entrepôts de données de santé</strong> hébergés sur des serveurs agréés.</li>
                    </ul>
                    <p>Nous veillons à ce que les systèmes d'information en santé travaillent ensemble à l'intérieur et au-delà des frontières organisationnelles pour améliorer la prise en charge des patients. C'est d'ailleurs dans ce cadre-là que nous proposons un ensemble de connecteurs aux services nationaux français :</p>
                    <ul>
                        <li><a href="../connecteurs/ins">le connecteur INS</a></li>
                        <li><a href="../connecteurs/mssante">le connecteur MS Santé</a></li>
                        <li><a href="../connecteurs/dmp">le connecteur DMP</a></li>
                    </ul>
                    <p>Nous accompagnons également sur les aspects administratifs et techniques pour l'utilisation de l'API Pro Santé Connect.</p>
                </div>
            </div>
            <div class="column alt-column is-4">
                <div class="content is-medium">
                    <figure class="image">
                        <Icon src={IllustrationInterp} />
                    </figure>
                </div>
            </div>
        </div>
    </div>
    };
  }

  render() {
    return (
        <TechPage 
            content={this.state.content} 
            activePage={this.state.page} 
            title={this.state.title}
            description={this.state.description}
            articles={this.state.articles}
        />
    );
  }
}
